// src/ContactForm.js

import React, { useState } from "react";
// import './ContactForm.css';
import MIcon from "../img/mc-icon.svg";
import { Link } from "react-router-dom";
import TitelIcon from "../img/mc-logo.svg";
import CreativeIcon from "../img/bedifferent.svg";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !description) {
      setError("All fields are required.");
      setSuccess("");
      return;
    }

    const formData = {
      name,
      email,
      description,
    };

    try {
      const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      setError("");
      setSuccess("Form submitted successfully!");
      // Clear form fields
      setName("");
      setEmail("");
      setDescription("");
    } catch (error) {
      setError("There was a problem with the submission: " + error.message);
      setSuccess("");
    }
  };

  const handleCancel = () => {
    setName("");
    setEmail("");
    setDescription("");
    setError("");
    setSuccess("");
  };

  const handleErrorClose = () => {
    setError("");
  };

  const handleSuccessClose = () => {
    setSuccess("");
  };

  return (
    <>
      <header>
        <div className="logo-box">
          <a href="#">
            <img src={TitelIcon} alt="murthy chat" />
          </a>

          <img className="tagline" src={CreativeIcon} alt="be creative" />
        </div>
      </header>
      <div className="contact-form-container">
        <div className="contact-form">
          <div className="form-logo">
            <p style={{ fontSize: "2rem" }}>Contact us</p>
          </div>
          {error && (
            <div className="error-message">
              {error}
              <button className="close-btn" onClick={handleErrorClose}>
                &times;
              </button>
            </div>
          )}
          {success && (
            <div className="success-message">
              {success}
              <button className="close-btn" onClick={handleSuccessClose}>
                &times;
              </button>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              type="name"
              id="name"
              name="name"
              label="Name"
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              required
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 4,
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiInputLabel-root": {
                  color: "#333",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#888",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#3f51b5",
                  },
                },
                margin: "10px 30px",
                width: "80%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="email"
              id="email"
              name="email"
              label="Email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 4,
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiInputLabel-root": {
                  color: "#333",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#888",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#3f51b5",
                  },
                },
                margin: "10px 30px",
                width: "80%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              multiline
              rows={1}
              type="text"
              id="description"
              name="description"
              label="Description"
              value={description}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              required
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 4,
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiInputLabel-root": {
                  color: "#333",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#888",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#3f51b5",
                  },
                },
                margin: "10px 30px",
                width: "80%",
              }}
            />

            <div className="button-group">
              <button
                type="submit"
                className="submit-btn"
                style={{ width: "80%", margin: "10px 30px" }}
              >
                Send Message
              </button>
              {/* <button type="button" className="cancel-btn" onClick={handleCancel}>
              Cancel
            </button> */}
            </div>
          </form>
        </div>
      </div>
      <footer>
        <ul>
          <li>
            <Link to="/terms-and-condition">Terms </Link>
          </li>
          <li>
            {" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/community-standards">Community Standards</Link>
          </li>
          <li>
            {" "}
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
        <span>©2024 Kuchi Inc</span>
      </footer>
      <Link to="/" className="floating-btn">
        <img src={MIcon} alt="" />
      </Link>
    </>
  );
}

export default ContactForm;
