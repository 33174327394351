import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import mcIcon from "../img/mc-icon.svg";
import Icon from "../img/village-radio.svg";
import TitelIcon from "../img/mc-logo.svg";
import CreativeIcon from "../img/bedifferent.svg";
import Send from "../img/send.svg";
import Refresh from "../img/refresh_FILL0_wght400_GRAD0_opsz24.svg";
import { EachElement } from "../Hooks/Each";

export default function Main() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [conversation, setConversation] = useState([]);

  const [showRowOut, setShowRowOut] = useState(false);
  const [showYou, setShowYou] = useState(false);
  const [showMurthy, setShowMurthy] = useState(false);
  const [showYouM, setShowYouM] = useState(false);
  const [showMurthyM, setShowMurthyM] = useState(false);
    const [good, setGood] = useState(false);

    const [lol, setLol] = useState(false);

  useEffect(() => {
    const cycleTimers = () => {
      setShowYou(false);
      setShowMurthy(false);
      setShowYouM(false);
      setShowMurthyM(false);
      setGood(false)
      setLol(false)

      const timerYou = setTimeout(() => {
        setShowYou(true);
      }, 1000);

      const timerMurthy = setTimeout(() => {
        setShowMurthy(true);
      }, 2000);

      const timerYouM = setTimeout(() => {
        setShowYouM(true);
      }, 3000);

      const timerMurthyM = setTimeout(() => {
        setShowMurthyM(true);
      }, 4000);

         const good = setTimeout(() => {
        setGood(true);
      }, 5000);
      const lolgood = setTimeout(() => {
        setLol(true);
      }, 6000);


      return () => {
        clearTimeout(timerYou);
        clearTimeout(timerMurthy);
        clearTimeout(timerYouM);
        clearTimeout(timerMurthyM);
      };
    };
// Start the cycle immediately
    cycleTimers(); 

    const interval = setInterval(cycleTimers, 7000); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  const mappings = {
    a: ["1", "11"],
    b: ["2", "22"],
    c: ["3", "33"],
    d: ["4", "44"],
    e: ["5", "55"],
    f: ["6", "66"],
    g: ["7", "77"],
    h: ["8", "88"],
    i: ["9", "99"],
    j: ["1'", '1"'],
    k: ["2'", '2"'],
    l: ["3'", '3"'],
    m: ["4'", '4"'],
    n: ["5'", '5"'],
    o: ["6'", '6"'],
    p: ["7'", '7"'],
    q: ["8'", '8"'],
    r: ["9'", '9"'],
    s: ['1"', '1"'],
    t: ['2"', '2"'],
    u: ['3"', '3"'],
    v: ['4"', '4"'],
    w: ['5"', '5"'],
    x: ['6"', '6"'],
    y: ['7"', '7"'],
    z: ['8"', '8"'],
  };

  const convertString = (input) => {
    let result = "      ";
    let currentWord = "             ";
    console.log(result, "result");

    for (let char of input) {
      const lowerChar = char.toLowerCase();

      if (lowerChar in mappings || char === "'") {
        currentWord += mappings[lowerChar] ? mappings[lowerChar][0] : char;
      } else if (char.match(/[a-z]/i)) {
        console.log(result, "result");
        result += currentWord + "   ''     ";
        currentWord = "        ";
      } else {
        result += currentWord + char;
        currentWord = "         ";
      }
    }

    if (currentWord) {
      result += currentWord;
    }

    return result;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const convertedData = convertString(input);
    setOutput(convertedData);
    setConversation([...conversation, { you: input, murthy: convertedData }]);
    setInput("");
  };

  const handleChange = (event) => {
    setInput(event.target.value);
    setOutput(convertString(event.target.value));
  };
  const ClearData = () => {
    setConversation([]);
  };

  return (
    <>
      <header>
        <div className="logo-box">
          <a href="#">
            <img src={TitelIcon} alt="murthy chat" />
          </a>
          <img className="tagline" src={CreativeIcon} alt="be creative" />
        </div>
      </header>
      <main>
        <section className="hero-section">
          <div className="container">
            <div className="heroleft">
              <div className="h-left-top">
                <span className="section-title">Chat Room</span>
                <h1>
                  <span className="Chatting_is">Chatting is</span>Fun. Creative.
                  Interesting.
                </h1>
              </div>
              <div></div>
              <div className="h-left-bottom">
                <div className="smiley_1"></div>
                <div className="smiley_2"></div>
                <div className="girl"></div>
                <div className="plant"></div>
                <div className="ncode-box">
                  <span>Anka lipi - 15'2'1 &nbsp; 3'97'9</span>
                  <div className="alp-box">
                    <div className="single-row">
                      <div className="letter-box">
                        <div className="ltop">a</div>
                        <div className="lltop">1</div>
                        <div className="lbottom">1</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">b</div>
                        <div className="lltop">2</div>
                        <div className="lbottom">2</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">c</div>
                        <div className="lltop">3</div>
                        <div className="lbottom">3</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">d</div>
                        <div className="lltop">4</div>
                        <div className="lbottom">4</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">e</div>
                        <div className="lltop">5</div>
                        <div className="lbottom">5</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">f</div>
                        <div className="lltop">6</div>
                        <div className="lbottom">6</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">g</div>
                        <div className="lltop">7</div>
                        <div className="lbottom">7</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">h</div>
                        <div className="lltop">8</div>
                        <div className="lbottom">8</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">i</div>
                        <div className="lltop">9</div>
                        <div className="lbottom">9</div>
                      </div>
                    </div>
                    <div className="single-row">
                      <div className="letter-box">
                        <div className="ltop">j</div>
                        <div className="lltop">1+0</div>
                        <div className="lbottom">1'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">k</div>
                        <div className="lltop">1+1</div>
                        <div className="lbottom">2'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">l</div>
                        <div className="lltop">1+2</div>
                        <div className="lbottom">3'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">m</div>
                        <div className="lltop">1+3</div>
                        <div className="lbottom">4'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">n</div>
                        <div className="lltop">1+4</div>
                        <div className="lbottom">5'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">o</div>
                        <div className="lltop">1+5</div>
                        <div className="lbottom">6'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">p</div>
                        <div className="lltop">1+6</div>
                        <div className="lbottom">7'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">q</div>
                        <div className="lltop">1+7</div>
                        <div className="lbottom">8'</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">r</div>
                        <div className="lltop">1+8</div>
                        <div className="lbottom">9'</div>
                      </div>
                    </div>
                    <div className="single-row">
                      <div className="letter-box">
                        <div className="ltop">s</div>
                        <div className="lltop">1+9</div>
                        <div className="lbottom">1"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">t</div>
                        <div className="lltop">2+0</div>
                        <div className="lbottom">2"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">u</div>
                        <div className="lltop">2+1</div>
                        <div className="lbottom">3"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">v</div>
                        <div className="lltop">2+2</div>
                        <div className="lbottom">4"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">w</div>
                        <div className="lltop">2+3</div>
                        <div className="lbottom">5"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">x</div>
                        <div className="lltop">2+4</div>
                        <div className="lbottom">6"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">y</div>
                        <div className="lltop">2+5</div>
                        <div className="lbottom">7"</div>
                      </div>
                      <div className="letter-box">
                        <div className="ltop">z</div>
                        <div className="lltop">2+6</div>
                        <div className="lbottom">8"</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="heroright">
              <div className="phone-chat">
                <div className="type-box">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      value={input}
                      onChange={handleChange}
                      placeholder="Chat with Murthy"
                    />

                    <button type="submit">
                      <img src={Send} alt="Send" />
                    </button>
                  </form>
                  {conversation.length === 0 ? (
                    false
                  ) : (
                    <img
                      src={Refresh}
                      alt="Send"
                      width={40}
                      height="1px"
                      onClick={ClearData}
                      className="Refresh"
                    />
                  )}
                </div>

                <div className="chart99">
                  {conversation.length === 0 ? (
                    <>
                      <div className="result-box">
                        {showYou && (
                          <div className="row-in">
                            <small>You</small>
                            <p>Hi</p>
                          </div>
                        )}

                        {showMurthy && (
                          <div className="row-out">
                            <small>Murthy</small>
                            <p> 89</p>
                          </div>
                        )}

                        {showYouM && (
                          <div className="row-in">
                            <small>You</small>
                            <p>i am good</p>
                          </div>
                        )}

                        {showMurthyM && (
                          <div className="row-out">
                            <small>Murthy</small>
                            <p> 9 14' 76'6'4 </p>
                          </div>
                        )}
                        {good && (
                          <div className="row-in">
                          <small>You</small>
                          <p>LOL</p>
                        </div>
                        )}

                        {lol && (
                          <div className="row-out">
                          <small>Murthy</small>
                          <p> 3'6'3'</p>
                        </div>
                        ) }
                      </div>
                    </>
                  ) : null}
                  <EachElement
                    of={conversation}
                    render={(message, index) => (
                      <div className="result-box" key={index}>
                        <div className="row-in">
                          <small>You</small>
                          <p>{message.you}</p>
                        </div>
                        <div className="row-out">
                          <small>Murthy</small>
                          <p>{message.murthy}</p>
                        </div>
                      </div>
                    )}
                  />
                </div>

                <div className="boy"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="bottom-section">
          <div className="multi-overlay"></div>
          <div className="container">
            <div className="bottomleft">
              <div className="bottom-top">
                <span className="section-title1">Village Radio</span>
                <h2>Share your village stories</h2>
              </div>
              <div className="bottom-content">
                <p className="one">Announcements</p>
                <p className="two">Activities</p>
                <p className="three">Events</p>
                <p className="four">News</p>
              </div>
            </div>
            <div className="bottomright">
              <div className="bt-pic-box">
                <img src={Icon} alt="" />
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <ul>
          <li>
            <Link to="/terms-and-condition">Terms </Link>
          </li>
          <li>
            {" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/community-standards">Community Standards</Link>
          </li>
          <li>
            {" "}
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
        <span>©2024 Kuchi Inc</span>
      </footer>
      <Link to="/" className="floating-btn">
        <img src={mcIcon} alt="" />
      </Link>
    </>
  );
}
