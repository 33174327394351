import { Children,Fragment } from "react";

export const EachElement = ({ render, of }) => (
    <>
      {of?.slice(0)?.reverse().map((item, index) => (
        <Fragment key={index}>
          {render(item, index)}
        </Fragment>
      ))}
    </>
  );